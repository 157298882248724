import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import { BreadArray, GameFolder } from "../../../../packages/blocks/contentmanagement/src/ContentManagementController";
import { setStorageData } from "../../../../packages/framework/src/Utilities";

// Customizable Area Start
interface Level {
  id: number;
  breadCrumbName: string;
  component: string;
}

interface BulkDeleteResult {
  gameFolder: number[];
  videos: number[];
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleSelect: any;
  selectMode: boolean;
  isItemSelected: any;
  isLayoutGrid: boolean;
  renderOptionBtn?: any;
  currentLevel: Level;
  videos?:any;
  onCloseLayoutModal: ()=>void
  showInformation:(item:any)=>void
  gameFolders?: Array<GameFolder>;
  callVideo?:any;
  setNewBreadCrumb?:any;
  contentManagementBread: BreadArray[];
  onDelete:(id: string, type: string) => void;
  callBackCreateSuccess: () => void;
  onShareCallback: (videoId: number,gameType: string,type: string|undefined) => void; 
  setPagination:(data:{page?: number,perPage?:number}) => void;
  onEditGameCallback: (videoId: number,gameType: string,type: string|undefined) => void;
  pagination:{
    totalRecords: number,
    totalPages: number,
    page:number
  }
  // Customizable Area End
}

interface SelectedItem {
  id: number,
  gameType: string,
  videos_size_gb: string,
}

export interface Video {
  custom_name?: string;
  type?: string;
  account_id: number,
  auto_generate_folder: string,
  auto_inner_generate_folder: string,
  created_by: string,
  files: {
    filename: string,
    url: string
  }[],
  game_date?: string,
  game_due_date?: string,
  game_score1: string,
  game_score2: string,
  game_status: string,
  game_type: string,
  id: number,
  oppo_team_name?: string,
  my_team_name?: string,
  opponent_team_name?: string,
  parent_folder: string,
  team_name?: string,
  uploading_for: string,
  thumbnail: string | null,
  folder_name: string| null
}

interface S {
  // Customizable Area Start
  anchorEl: any,
  page: number,
  showIcons: number,
  cardsArray: any,
  isLayoutGrid: boolean,
  count: number,
  selectedItems: SelectedItem[];
  selectMode: boolean;
  token: string;
  videos: Video[];
  displayedVideos: Video[];
  currentItem: any;
  informationModalOpen: boolean;
  layout: string,
  timeGroup:string,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class VideosController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getVideosCall: string | Message = ''
  deleteVideo: string | Message = ''
  bulkDeleteVideosCallId: string | Message = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      anchorEl: null,
      page:1,
      showIcons:-1,
      cardsArray:[],
      isLayoutGrid: true,
      count: 5,
      selectedItems: [],
      selectMode: false,
      token: "",
      videos: [],
      displayedVideos: [],
      currentItem: null,
      informationModalOpen:false,
      layout:"Row",
      timeGroup:"OFF"
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) return;
    
    this.handleGetVideosCall(message);
    this.handleDeleteVideosCall(message)
    this.handleBulkDeleteVideosCall(message);
    // Customizable Area End
  }

  // Customizable Area Start

  handleResponse = (responseJson: any, errorResponse: any, successCallback: any) => {
    if (responseJson) {
      successCallback(responseJson);
    } else {
      this.parseApiErrorResponse(errorResponse);
    }
    this.parseApiCatchErrorResponse(errorResponse);
  };

  handleGetVideosCall = (message: Message) => {
    const dataMessage = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    if (this.getVideosCall !== null && this.getVideosCall === dataMessage) {
      this.handleResponse(
        message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)),
        message.getData(getName(MessageEnum.RestAPIResponceErrorMessage)),
        (responseJson: any) => {
          this.setState({ videos: responseJson.data, displayedVideos: responseJson.data.slice(0, 5) })
        }
      );
    }
  };
  handleDeleteVideosCall = (message: Message) => {
    const dataMessage = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    if (this.deleteVideo !== null && this.deleteVideo === dataMessage) {
      this.handleResponse(
        message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)),
        message.getData(getName(MessageEnum.RestAPIResponceErrorMessage)),
        (responseJson: any) => {
          this.setState({anchorEl: null})
          this.props.callBackCreateSuccess()
        }
      );
    }
  };

  handleBulkDeleteVideosCall = (message: Message) => {
    const dataMessage = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    if (this.bulkDeleteVideosCallId !== null && this.bulkDeleteVideosCallId === dataMessage) {
      this.handleResponse(
        message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)),
        message.getData(getName(MessageEnum.RestAPIResponceErrorMessage)),
        (responseJson: any) => {
          if(responseJson.message){
            toast.success("Deleted Successfully")
            this.props.callBackCreateSuccess();
            this.setState({selectMode:false,selectedItems:[]})
          }
        }
      );
    }
  };

  getVideosApi = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
      'ngrok-skip-browser-warning': 'true' 
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getVideosCall = requestMessage.messageId;
      
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.videosInFolderURL}/${this.props.currentLevel.id}/get_folder_attachments`
        );
        
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header));

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  deleteVideosApi = async (id:string, type: string) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
      );
      this.deleteVideo = requestMessage.messageId;
      const endPoint = type =='game_folders' ? configJSON.videosInFolderURL : configJSON.getVideoDetailEndPoint
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${endPoint}/${id}`
        );
        
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header));

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleCount = (item: any) => {
    this.setState({ count: Number(item) })
    this.props.setPagination({perPage: Number(item)})
  }

  goToVieoDetail = async () => {
    this.setState({ anchorEl: null });
    await setStorageData("contentManagementBread", this.props.contentManagementBread);
    this.props.navigation.navigate("VideoEvent");
  };
  handleInformation=(item:any)=>{    
    this.setState({informationModalOpen:true, currentItem:item})
  };
  handleCloseInformation=()=>{
    this.setState({informationModalOpen:false,currentItem:null})
  }
  handleClose = () => {
    this.setState({ anchorEl: null,currentItem: null });
  };
  handleChange = (event: any, value: number) => {
    this.setState({ page: value , displayedVideos: this.state.videos.slice((value - 1) * 5,value * 5)});
    this.props.setPagination({page:value})
  };
  handleShowIcons= (index: any) => {
    if(this.props.selectMode) return;
    this.setState({showIcons: index});
  };
  handleHideIcons= () => {
    this.setState({showIcons: -1});
  };
  handleSelect = (id: number, gameType: string,videos_size_gb: string) => { 
    const existingIndex = this.state.selectedItems.findIndex(item => item.id === id);

    if (existingIndex !== -1) {
      this.setState(prevState => ({
        selectedItems: prevState.selectedItems.filter(item => item.id !== id)
      }));
    } else {
      const newSelected: SelectedItem = { id: id, gameType: gameType,videos_size_gb: String(videos_size_gb) };
      this.setState(prevState => ({
        selectedItems: [...prevState.selectedItems, newSelected]
      }));
    }
  }

  handleSaveLayout=(layout:string,time:string)=>{
    localStorage.setItem("videoLayoutView", JSON.stringify({layout,timeGroup:time}));
     this.setState({layout,timeGroup:time});
    this.props.onCloseLayoutModal();
  }

  isItemSelected = (id: number) => {
    return this.state.selectedItems.some(item => item.id === id);
  }

  async componentDidMount() {
    const token = window.localStorage.getItem("authToken") || "";    
    this.setState({ token });
    if(window.api){
      window.api.receive('refresh-folder-game', () => {
      });
    }
    this.setLayoutView()
  }

  async componentWillUnmount() {
    if (window.api) {
      window.api.removeAllListeners('refresh-folder-game');
    }
  }
  setLayoutView() {
    const layOutView = window.localStorage.getItem("videoLayoutView") || ""
    if(layOutView){
      const layoutViewData = JSON.parse(layOutView);
      this.setState({layout: layoutViewData.layout,timeGroup:layoutViewData.timeGroup})
    }
  }
  componentDidUpdate(prevProps: any, prevState: any) {
    if (this.props.selectMode !== prevProps.selectMode) {
      this.setState({ selectedItems: [] })
    }
  }

  goToVideoEventScreen = async (videoId: number) => {
    await setStorageData("contentManagementBread", JSON.stringify(this.props.contentManagementBread))
    this.props.navigation.navigate("VideoEvent", {videoId})
  }
  handleEditBtn = (video: any) => {
    if (video.type === "attachment") {
      this.goToVideoEventScreen(video.id);
    } else {
      this.props.setNewBreadCrumb(video, false)
      this.props.callVideo(video.id)
    }
  }

  handleShare = (videoId: number,gameType:string,type: string | undefined) => {      
      this.props.onShareCallback(videoId,gameType,type)
  }

  handleEditGameData = (videoId: number,gameType:string,type: string | undefined) => {      
    this.props.onEditGameCallback(videoId,gameType,type)
}

  bulkDeleteVideos=()=>{

    const resultBody: BulkDeleteResult = this.state.selectedItems.reduce<BulkDeleteResult>((acc, item) => {
      if (item.gameType === "game_folders") {
        acc.gameFolder.push(item.id);
      } else if (item.gameType === "attachment") {
        acc.videos.push(item.id);
      }
      return acc;
    }, { gameFolder: [], videos: [] });
    
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.state.token,
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
        );
        this.bulkDeleteVideosCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.bulkDeleteVideosEndPoint
          );
          
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header));

            requestMessage.addData(
              getName(MessageEnum.RestAPIRequestBodyMessage),
              JSON.stringify(resultBody)
            );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.deleteMethod
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    };

  // Customizable Area End
}
