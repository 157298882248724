import React from "react";

// Customizable Area Start
import {
  Card, CardContent, CardActionArea, Checkbox, CheckboxProps, Box, Grid, Typography, Button, InputBase
  , styled, InputAdornment, CardMedia
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuButton from "../../../components/src/MenuButton";
import { star, deletes, warning, logo, edit, clip, share, report } from "./assets";
const defaultUploadImg = require("../../../components/src/assets/default_upload.png");

export const StyledMenu = styled((props: any) => (
  <Menu
    data-test-id="menu"
    elevation={0}
    anchorOrigin={{
      horizontal: "right",
      vertical: "bottom",
    }}
    transformOrigin={{
      horizontal: "right",
      vertical: "top",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    border: "1px solid #FF881A",
    width: 250,
    marginTop: theme.spacing(8),
    backgroundColor: "#1D1D1F",
    borderRadius: 10,
    color: "#CFD1D4",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    [theme.breakpoints.down('lg')]: {
      width: 200,
    },
    [theme.breakpoints.down('md')]: {
      width: 149,
    },
    [theme.breakpoints.down('sm')]: {
      width: 101,
    },
    "& .MuiMenu-list": {
      margin: "10px",
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      [theme.breakpoints.down('lg')]: {
        fontSize: 15,
        marginRight: theme.spacing(1.2),
      },
      [theme.breakpoints.down('md')]: {
        fontSize: 13,
        marginRight: theme.spacing(1),
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 11,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 9,
      },
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        marginRight: theme.spacing(1.5),
      },
      "&:hover": {
        backgroundColor: "#FF881A",
        color: "#1D1D1F",
        border: "1px solid #FF881A",
        borderRadius: 5,
      },
    },
  },
}));

const StyledPagination = styled(Pagination)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  backgroundColor: 'transparent',
  fontSize: 20,
  margin: "50px 0px",
  '& .MuiPaginationItem-root': {
    fontSize: 20,
    color: "#CFD1D4",
  },
  '& .Mui-selected': {
    color: "#E87C18",
  },
  '& .MuiPaginationItem-page.Mui-selected:hover': {
    backgroundColor: 'transparent',
  }
}));
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import ListVideo from "./ListVideo.web";
import InformationModal from "./components/InformationModal.web";
import SelectedFilesOverlay from "./components/SelectedFilesOverlay.web"
import LayoutModal from "./components/LayoutModal.web";
import ImageComponent from "../../../components/src/ImageComponent.web";
// Customizable Area End

import VideosController, { Props, configJSON } from "./VideosController";
import { GameFolder } from "../../../../packages/blocks/contentmanagement/src/ContentManagementController";

export default class Videos extends VideosController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  /* istanbul ignore next */
  formatGameFolder = (gameFolders: Array<GameFolder>) => {
    return gameFolders.map(item => ({
      ...item,
      win: true,
      attachment: {
        id: item.id,
        game_name: item.attributes.folder_name,
        game_score1: '',
        game_score2: '',
        game_status: '',
        team_name: item.attributes.my_team_name,
        oppo_team_name: item.attributes.opponent_team_name,
        game_date: item.attributes.game_due_date,
        game_type: item.attributes.game_type,
        created_by: item.attributes.created_by,
        thumbnail: item.attributes.thumbnail,
        videos_size_gb:item.attributes.videos_size_gb
      }
    }))
  }

  renderVideoCardThumb = (index: any, item: any) => {
    return (
      <Box data-test-id="videoCardThumb" onMouseOver={() => this.handleShowIcons(index)}
        onMouseLeave={() => this.handleHideIcons()} style={{ boxShadow: `0 -8px 8px 0 ${item.win ? "#8DE301" : "#FF1919"}`, height: 240, overflow: "hidden", position: "relative" }}>
        <CardMedia
          component="div"
          data-test-id={`card${index}`}
        >
          <ImageComponent src={item.thumbnail || ""} alt={"Thumbnail"} fallbackSrc={defaultUploadImg} />
        </CardMedia>
        {this.state.showIcons === index && <div style={{ pointerEvents: "none", position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, backgroundColor: 'rgba(0, 0, 0, 0.7)', zIndex: 1 }} />}
        {this.state.showIcons === index ?
          <Box style={{}}>
            <img src={logo} alt="Overlay" className="logo" width="90px" />
            <img src={star} alt="Overlay" className="star" />
            <img src={deletes} alt="Overlay" className="delete" />
            <img src={warning} alt="Overlay" className="warning" />
            <Typography className="summary">Game Summary</Typography>
            <Typography className="score">Game Score</Typography>
            <Typography className="scoreValue"><span style={{ color: "#8DE301", fontSize: 36, fontWeight: 500 }}>{item?.game_score1 || "_"}:</span><span>{item?.game_score2 || "_"}</span></Typography>
            <Button variant="outlined" className="edit" onClick={() => this.goToVideoEventScreen(item.id)}> Edit <img src={edit} style={{ paddingLeft: 5 }} /></Button>
            <Button variant="outlined" className="clip"> Clips <img src={clip} style={{ paddingLeft: 7 }} /></Button>
            <Button onClick={()=>this.handleShare(item.id,item.game_type,item.type)} variant="outlined" className="share" data-test-id="share-btn"> Share <img src={share} style={{ paddingLeft: 5 }} /></Button>
            <Button variant="outlined" className="report"> Report <img src={report} style={{ paddingLeft: 5 }} /></Button>
          </Box>
          : ""
        }
      </Box>
    )
  }

  renderListVideoGrid = () => {
    const gameFolders = this.formatGameFolder(this.props.gameFolders || [])
    

    return <Box className="container listVideo" style={{ display: "flex", flexWrap: "wrap" }}>
      {
        [...this.props.videos, ...gameFolders].map((item: any, index: number) => {  
                  
          const videoCustomName = item?.custom_name ? `( ${item.custom_name} )` : "";
          

          return (
            <Box key={index} data-test-id={`grid-video-card${index}`} onClick={() => { if (this.props.selectMode) { this.handleSelect(item.id, item.type, item.videos_size_gb); } }} className={this.state.showIcons === index ?"card":"card2"} style={{ boxShadow: this.isItemSelected(item.id) ? "0 2px 0 rgba(255, 136, 26, 1),0 -2px 0 rgba(255, 136, 26, 1)" : 'none', borderRadius: 10 }}>
              <Box style={{ position: 'relative', borderRadius: '10px', overflow: 'hidden' }}>
                <Box>
                  {this.isItemSelected(item.id) && <Checkbox size="medium" checked={true} style={{ color: "#FF8826", position: "absolute", top: "15px", right: "15px", zIndex: 3, transform: 'scale(2)',boxShadow: "8px 8px 24px 0px rgba(255, 136, 26, 0.2)" }} />}
                  {this.props.selectMode && <Checkbox size="medium" checked={true} style={{ color: "rgba(207, 209, 212, 1)", position: "absolute", top: "15px", right: "15px", zIndex: 2, transform: 'scale(2)' }} />}
                    {this.renderVideoCardThumb(index,item)}
                  <Box style={{ boxShadow: `0 -8px 8px 0 ${item?.game_status?.toLowerCase() === "win" ? "#8DE301" : "#FF1919"}`, width: "100%", height: "5px", position: "absolute" }}></Box>
                  <CardContent className="cardContent">
                    <Box className="cardBox">
                      <Typography className="cardTypo">
                        {`${item?.team_name || "_"} VS ${item?.oppo_team_name || "_"} ${videoCustomName}`}
                      </Typography>
                    </Box>
                    <Box className="cardBoxParent">
                      <Box className="" >
                        <Typography className="cardTypo">Game Date:</Typography>
                        <Typography className="cardTypo"> Game Type:</Typography>

                      </Box>
                      <Box className="">
                        <Typography className="cardTypo2">{item?.game_date}</Typography>
                        <Typography className="cardTypo2">{item?.game_type}</Typography>
                      </Box>
                      <Box className="">
                        <MenuButton options={[
                          {label: "Information", onClick: () => this.handleInformation(item)},
                          {label: "Move Folder", onClick: () => {}},
                          {label: "Manage Access", onClick: () => {this.handleShare(item.id,item.game_type,item.type)}},
                          { label: "Edit Game Data", onClick: () => {this.handleEditGameData(item.id,item.game_type,item.type)} },
                          {label: "Delete", onClick: () => {this.deleteVideosApi(item.id,item.type)}},
                        ]}/>
                        <Typography noWrap className="created">Created By: {" " + item.created_by}</Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </Box>
                {this.isItemSelected(item.id) && <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, backgroundColor: 'rgba(0, 0, 0, 0.7)', zIndex: 1 }} />}
              </Box>
            </Box>
          )
        })
      }
    </Box>
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }} id="video-content">
        <InformationModal isActive={this.state.informationModalOpen} onClose={this.handleCloseInformation} videoData={this.state.currentItem || {}} />
        <LayoutModal isActive={this.props.isLayoutGrid} onClose={this.props.onCloseLayoutModal} onSave={this.handleSaveLayout} />
        
        <StyledGridItem>  
          {
            this.state.layout==="Grid" ? 
              this.renderListVideoGrid() :
              <ListVideo
                videos={[...this.props.videos, ...this.formatGameFolder(this.props.gameFolders || [])]}
                isLayoutGrid={this.props.isLayoutGrid}
                selectMode={this.props.selectMode}
                handleSelect={this.handleSelect}
                isItemSelected={this.isItemSelected}
                currentLevel={this.props.currentLevel}
                showInformation={this.handleInformation.bind(this)}
                navigation={this.props.navigation}
                id={""}
                onCloseLayoutModal={this.props.onCloseLayoutModal}
                callVideo={this.props.callVideo}
                setNewBreadCrumb={this.props.setNewBreadCrumb}
                contentManagementBread={this.props.contentManagementBread}
                onDelete={this.deleteVideosApi}
                callBackCreateSuccess={this.props.callBackCreateSuccess}
                onShareCallback={this.handleShare.bind(this)}
                setPagination={this.props.setPagination}
                onEditGameCallback={this.handleEditGameData.bind(this)}
                pagination={this.props.pagination}
              />
          }
          <Box>
           { this.state.selectedItems.length>0 && <SelectedFilesOverlay data-test-id="overlay" selectedItems={this.state.selectedItems} onDelete={this.bulkDeleteVideos}/>}
            <StyledPagination count={this.props.pagination.totalPages} page={this.props.pagination.page} onChange={this.handleChange} />
          </Box>
        </StyledGridItem>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const StyledGridItem = styled('div')(({ theme }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  margin: "0 40px",
  '& .summary': {
    position: "absolute",
    top: 8,
    left: 132,
    fontSize: 24,
    color: "#FFF",
    fontWeight: 500,
    fontFamily: "Poppins",
    textAlign: "center",
    zIndex: 2
  },
  '& .edit': {
    width: "96px",
    textTransform: 'capitalize',
    borderRadius: 8,
    color:'black',
    position: "absolute",
    top: 192,
    left: 9,
    fontSize: 16,
    fontFamily: "Poppins",
    backgroundColor: "#FFB03A",
    borderColor: "#FFB03A",
    zIndex: 2
  },
  '& .clip': {
    width: "96px",
    borderRadius: 8,
    position: "absolute",
    top: 192,
    left: 113,
    fontSize: 16,
    color:'black',
    fontFamily: "Poppins",
    textTransform: 'capitalize',
    backgroundColor: "#BCBCBC",
    borderColor: "#BCBCBC",
    zIndex: 2
  },
  '& .share': {
    width: "96px",
    borderRadius: 8,
    position: "absolute",
    textTransform: 'capitalize',
    top: 192,
    left: 217,
    fontSize: 16,
    fontFamily: "Poppins",
    color:'black',
    backgroundColor: "#A5B5D4",
    borderColor: "#A5B5D4",
    zIndex: 2
  },
  '& .report': {
    width: "100px",
    borderRadius: 8,
    position: "absolute",
    textTransform: 'capitalize',
    top: 192,
    left: 321,
    fontSize: 16,
    fontFamily: "Poppins",
    color:'black',
    backgroundColor: "#32A228",
    borderColor: "#32A228",
    zIndex: 2
  },
  '& .score': {
    position: "absolute",
    top: 71,
    left: 197,
    fontSize: 20,
    color: "#FFF",
    fontFamily: "Poppins",
    textAlign: "center",
    zIndex: 2
  },
  '& .scoreValue': {
    position: "absolute",
    top: 101,
    left: 215,
    fontSize: 36,
    color: "#CFD1D4",
    fontWeight: 500,
    fontFamily: "Poppins",
    zIndex: 2
  },
  '& .delete': {
    position: "absolute",
    top: 14,
    left: 384,
    zIndex: 2
  },
  '& .warning': {
    position: "absolute",
    top: 50,
    left: 384,
    zIndex: 2
  },
  '& .star': {
    position: "absolute",
    top: 99,
    left: 384,
    zIndex: 2
  },
  '& .card': {
    width: "426px",
    height: "348px",
    backgroundColor:"#EF8E3B",
    BorderRadius: "10px",
    margin: 15,
  },
  '& .card2': {
    width: "426px",
    height: "348px",
    backgroundColor:"rgb(37, 37, 39)",
    BorderRadius: "10px",
    margin: 15,
  },
  '& .overlayImage': {
    width:102,height:87,borderBottomRightRadius:32,backgroundColor:"#252527",display:"flex",justifyContent:"center",alignItems:"center",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 2
  },
  '& .logo': {
    position: "absolute",
    top: 89,
    left: 16,
    zIndex: 2
  },
  '& .cardContent': {
    width: "426px",
    height: "94px",
    padding:"5px 16px",
    background: "#252527",
    // borderBottom: "3px solid red",
  },
  '& .cardBox': {
    width: "426px",
    height: "24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  '& .cardBoxParent': {
    display: "flex",
    justifyContent: "space-between",
    paddingTop:"8px",
  },
  '& .cardTypo1': {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "500",
    textAlign: "center",
    color: "#CFD1D4",
  },
  '& .cardTypo': {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "500",
    textAlign: "right",
    color: "#CFD1D4",
  },
  '& .cardTypo2': {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "500",
    color: "#CFD1D4",
  },
  '& .cardTypoDots': {
    display:"flex",
    marginRight:"25px",
    justifyContent:"flex-end"
  },
  '& .cardTypoDot': {
    paddingLeft: "70%",
  },
  '& .created': {
    color: "#666666",
    fontSize: "14px",
    fontWeight: "500",
    fontFamily: "Poppins",
    paddingRight: 22,
    maxWidth: "190px",
    marginTop:"3px",
    maxHeight: "24px"
  },
  '& .pathsParent': {
    width: "95vw",
    margin: "0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontFamily: "sans-serif",
    marginBottom: 10,
  },
  '& .paths': {
    color: "#CFD1D4",
    Lineheight: 10
  },
  '& .selectFiles': {
    color: "#CFD1D4",
    fontSize: 20,
    fontWeight: 400,
    fontFamily: "sans-serif",
    [theme.breakpoints.down('lg')]: {
      fontSize: 20,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  '& .pathItems': {
    fontSize: 24,
    [theme.breakpoints.down('lg')]: {
      fontSize: 20,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  '& .pathIcon': {
    marginLeft: 10,
    marginRight: 10,

  },
  '& .container': {
    width: "95vw",
    margin: "20px 0 40px;",
  },
  "& .container.listVideo": {
    flex: 1
  },
  '& .grid': {
    display: "flex",
    alignItems: "center",
    gap: 24,
  },
  '& .grid2': {
    display: "flex",
    alignItems: "center",
    gap: 24,
  },
  '& .gridItem1': {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 30px",
    paddingLeft: 20,
    borderRadius: 10,
    fontSize: 20,
    width: "69%",
    color: "#CFD1D4",
    backgroundColor: "252527",
    [theme.breakpoints.down('lg')]: {
      fontSize: 18,
      padding: "10px 20px",

    },
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      padding: "10px 10px",

    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      padding: "10px"
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,

    },
  },
  '& .gridItem2': {
    borderRadius: 10,
    fontSize: 20,
    padding: "5px 18px",
    color: "#CFD1D4",
    backgroundColor: "252527",
    textTransform: "none",
    [theme.breakpoints.down('lg')]: {
      fontSize: 18,
      padding: 14,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      padding: 12,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      padding: 10,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
      padding: 8,
    },
  },
  '& .inputbase': {

    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 10,
    fontSize: 20,
    color: "#CFD1D4",
    backgroundColor: "252527",
    width: "53%",
    textTransform: "none",
    [theme.breakpoints.down('lg')]: {
      fontSize: 18,
      width: "45%",
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
      width: "40%",
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,

    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,

    },
  },
  '& .img': {
    marginLeft: 10,
  },
  '& .icons': {
    [theme.breakpoints.down('lg')]: {
      width: 30,
    },
    [theme.breakpoints.down('md')]: {
      width: 20,
    },
    [theme.breakpoints.down('sm')]: {
      width: 15,
    },
    [theme.breakpoints.down('xs')]: {
      width: 10,
    },
  }
}));
// Customizable Area End
